<template>
  <b-row class="wrap-border">

    <!--    热议与时间线卡片-->
    <b-col>
      <b-card>
        <!-- body -->
        <b-card-body>
          <b-tabs>
            <b-tab
                active
                title="热议">
              <b-overlay
                  id="overlay-hot-discussions"
                  :show="discussions_show"
                  variant="light"
                  opacity="1.0"
                  blur="2px"
                  rounded="sm">
                <div
                    v-for="discussion in discussions.items">
                  <hot-discussion-card
                      :content="discussion.content"
                      :discussion_id="discussion.discussion_id"
                      :release_time="discussion.release_time"
                      :title="discussion.title"
                      :user_avatar="discussion.user_avatar"
                      :user_id="discussion.user_id"
                      :user_name="discussion.user_name"
                      :link="discussion.link">
                  </hot-discussion-card>
                </div>
                <b-button
                    size="sm"
                    variant="outline-primary"
                    v-on:click="refreshHotDiscussions">
                  <b-overlay
                      :show="more_discussions_overlay"
                      rounded="sm">
                    显示更多
                  </b-overlay>
                </b-button>
              </b-overlay>
            </b-tab>
            <b-tab
                title="时间线">
              <b-overlay
                  id="overlay-timeline"
                  :show="timeline_show"
                  variant="light"
                  opacity="1.0"
                  blur="2px"
                  rounded="sm">
                <app-timeline v-for="item in timeline.items">
                  <app-timeline-item
                      :subtitle="item.content"
                      :title="item.release_time">
                  </app-timeline-item>
                  <br>
                </app-timeline>

                <b-button
                    size="sm"
                    variant="outline-primary"
                    v-on:click="refreshNews">
                  <b-overlay
                      :show="more_news_overlay"
                      rounded="sm">
                    显示更多
                  </b-overlay>
                </b-button>


              </b-overlay>
            </b-tab>
          </b-tabs>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardHeader,
  BCardBody,
  BTabs,
  BTab,
  BListGroup,
  BListGroupItem,
  BCardText,
  BMedia,
  BImg,
  BOverlay,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import StockIndexCard from "@/views/Home/StockIndexCard";
import HotDiscussionCard from "@/views/Home/HotDiscussionCard";
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardHeader,
    BCardBody,
    BTabs,
    BTab,
    BListGroup,
    BListGroupItem,
    BCardText,
    BMedia,
    BImg,
    BOverlay,
    StockIndexCard,
    HotDiscussionCard,
    AppTimeline,
    AppTimelineItem,
    BButton,
    BSpinner,
  },
  data() {
    return {
      sh_show: true,
      sz_show: true,
      st_show: true,
      st50_show: true,
      hot_show: true,
      discussions_show: true,
      timeline_show: true,
      more_news_overlay: false,
      more_discussions_overlay: false,
      oneHour: [],
      twentyFourHours: [],
      discussions: [],
      timeline: [],
    }
  },
  created() {
    this.getHotDiscussions()
    this.getNews()
  },
  methods: {
    getHotDiscussions() {
      this.$http.get('/api/data/get_hot_discuss')
          .then(
              response => {
                this.discussions = response.data.data
                this.discussions.items.forEach(element => element.link = "https://xueqiu.com/" + element.user_id + "/" + element.discussion_id)
                this.discussions_show = false
              })
    },
    getNews() {
      this.$http.get('/api/data/get_news')
          .then(
              response => {
                this.timeline = response.data.data
                this.timeline_show = false
              })
    },
    refreshHotDiscussions() {
      this.more_discussions_overlay = true
      this.$http.get('/api/data/get_hot_discuss?max=' + this.discussions.next_max)
          .then(
              response => {
                let res = response.data.data.items
                res.forEach(element => element.link = "https://xueqiu.com/" + element.user_id + "/" + element.discussion_id)
                this.discussions.items = [...this.discussions.items, ...res]
                this.discussions.next_max = response.data.data.next_max
                this.discussions_show = false
                this.more_discussions_overlay = false
              })
    },
    refreshNews() {
      this.more_news_overlay = true
      this.$http.get('/api/data/get_news?max=' + this.timeline.next_max)
          .then(
              response => {
                this.timeline.items = [...this.timeline.items, ...response.data.data.items]
                this.timeline.next_max = response.data.data.next_max
                this.timeline_show = false
                this.more_news_overlay = false
              })
    },
  },
}


</script>

<style>

</style>
